* {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 12px;
  line-height: 2em;
}

body {
  background-color: #E3E3DF;
}

.container {
  margin: 30px;
}

/* GLOBAL */
.clearLink {
  text-decoration: none;
  color: inherit
}
.arrow {
  position: relative;
  top: 1px;
}
.tag {
  opacity: 0.5;
}
.strike {
  opacity: 0.3 !important;
  text-decoration: line-through !important;
}
.links {
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}
.links li {
  margin: 0px;
  display: inline-block;
  padding-right: 28px;
}
.links li:last-child {
  padding-right: 0px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.spacer160Top {
  padding-top: 160px;
}
.spacer160Bottom {
  padding-bottom: 160px;
}
.spacer60Bottom {
  padding-bottom: 60px;
}

/* Header */
.header {
  position: sticky;
  top: 30px;
}

/* Body */
.bodyContainer {
  /* max-width: 928px; */
}
.bodyContentBlock {
  max-width: 928px;
}

/* Footer */
.footer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #E3E3DF;
}

/* Screens */
@media only screen and (max-width: 600px) {
  .flex {
    display: block;
  }
  .bodyContainer {
    display: block;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }  
  .spacer160Top {
    padding-top: 60px;
  }
}